import { auth, db } from "@/firebase";
import { doc, updateDoc } from "firebase/firestore";

export default {
  install(app) {
    window.addEventListener("beforeunload", async () => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, { isOnline: false });
      }
    });

    app.config.globalProperties.$setOnlineStatus = async (status) => {
      const user = auth.currentUser;
      if (user) {
        const userDocRef = doc(db, "users", user.uid);
        await updateDoc(userDocRef, { isOnline: status });
      }
    };
  },
};
