<script setup>
import { ref, reactive } from "vue";
import { auth, db } from "@/firebase";
import { doc, setDoc, collection, addDoc } from "firebase/firestore";
import { createUserWithEmailAndPassword } from "firebase/auth";
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const emit = defineEmits(["close"]);

const cvrInput = ref("");
const searchResult = ref(null);
const message = ref(null);
const isLoading = ref(false);

const manualCompany = reactive({
  name: "",
  address: "",
  zipcode: "",
  city: "",
  phone: "",
  email: "",
  vat: "",
});

const fetchCompanyData = async () => {
  if (!cvrInput.value) {
    message.value = { type: "error", text: "Indtast venligst et CVR-nummer." };
    return;
  }

  isLoading.value = true;
  try {
    const response = await fetch(
      `https://cvrapi.dk/api?country=dk&search=${cvrInput.value}`,
      {
        headers: {
          "User-Agent": "MyAppName",
        },
      },
    );

    if (response.ok) {
      const data = await response.json();
      console.log("Virksomhedsdata:", data);
      searchResult.value = data;
      message.value = null;
    } else {
      message.value = {
        type: "error",
        text: "Fejl ved hentning af virksomhedsdata.",
      };
    }
  } catch (error) {
    console.error("Error:", error);
    message.value = { type: "error", text: "Der opstod en fejl ved søgning." };
  } finally {
    isLoading.value = false;
  }
};

const handleAddCompany = async (isManual = false) => {
  const companyData = isManual ? manualCompany : searchResult.value;

  if (companyData) {
    isLoading.value = true;
    const { name, address, zipcode, city, phone, email, vat } = companyData;
    const fullAddress = `${address}, ${zipcode} ${city}`;
    const defaultEmail = email || `${name.replace(/\s+/g, "")}@example.com`;
    const password = "123456789"; // Temporary password

    try {
      const userCredential = await createUserWithEmailAndPassword(
        auth,
        defaultEmail,
        password,
      );
      const uid = userCredential.user.uid;

      await setDoc(doc(db, "users", uid), {
        name: name,
        address: fullAddress,
        phone: phone,
        email: defaultEmail,
        role: "user",
        isOnline: false,
        cvr: vat,
      });

      const companyDoc = await addDoc(collection(db, "companies"), {
        name: name,
        address: fullAddress,
        phone: phone,
        email: email || "Ikke oplyst",
        cvr: vat,
        userId: uid,
      });

      await setDoc(
        doc(db, "users", uid),
        { companyId: companyDoc.id },
        { merge: true },
      );

      console.log(
        "Bruger og virksomhed oprettet og tilføjet til Firestore:",
        name,
      );
      message.value = {
        type: "success",
        text: `Virksomhed "${name}" er blevet tilføjet succesfuldt.`,
      };
      cvrInput.value = "";
      searchResult.value = null;

      // Vent i 3 sekunder før modalen lukkes
      setTimeout(() => {
        emit("close");
      }, 3000);
    } catch (error) {
      console.error("Error adding company to Firebase:", error);
      console.error("Error code:", error.code);
      console.error("Error message:", error.message);
      message.value = {
        type: "error",
        text: `Fejl ved tilføjelse af virksomhed: ${error.message}`,
      };
    } finally {
      isLoading.value = false;
    }
  }
};

const closeModal = () => {
  emit("close");
};
</script>

<template>
  <div class="company-add-modal">
    <div class="modal-header">
      <h5 class="modal-title">Tilføj Virksomhed</h5>
      <button @click="closeModal" class="close-button">&times;</button>
    </div>
    <div class="modal-body">
      <form @submit.prevent="fetchCompanyData">
        <ArgonInput
          v-model="cvrInput"
          type="text"
          placeholder="CVR-nummer"
          aria-label="CVR-nummer"
        />
        <ArgonButton
          type="submit"
          fullWidth
          color="dark"
          variant="gradient"
          class="my-4 mb-2"
          :disabled="isLoading"
        >
          Søg virksomhed
        </ArgonButton>
      </form>

      <div v-if="searchResult" class="mt-4">
        <h6 class="text-sm font-weight-bold mb-2">Søgeresultat</h6>
        <p class="text-sm">Navn: {{ searchResult.name }}</p>
        <p class="text-sm">
          Adresse: {{ searchResult.address }}, {{ searchResult.zipcode }}
          {{ searchResult.city }}
        </p>
        <p class="text-sm">Telefon: {{ searchResult.phone }}</p>
        <p class="text-sm">Email: {{ searchResult.email || "Ikke oplyst" }}</p>
        <ArgonButton
          @click="handleAddCompany(false)"
          fullWidth
          color="success"
          variant="gradient"
          class="my-4 mb-2"
          :disabled="isLoading"
        >
          Tilføj virksomhed
        </ArgonButton>
      </div>

      <div class="mt-4 position-relative text-center">
        <p
          class="text-sm font-weight-bold mb-2 text-secondary text-border d-inline z-index-2 bg-white px-3"
        >
          eller
        </p>
      </div>

      <form @submit.prevent="handleAddCompany(true)" class="mt-4">
        <ArgonInput
          v-model="manualCompany.name"
          type="text"
          placeholder="Virksomhedens navn"
          aria-label="Virksomhedens navn"
        />
        <ArgonInput
          v-model="manualCompany.address"
          type="text"
          placeholder="Adresse"
          aria-label="Adresse"
        />
        <ArgonInput
          v-model="manualCompany.zipcode"
          type="text"
          placeholder="Postnummer"
          aria-label="Postnummer"
        />
        <ArgonInput
          v-model="manualCompany.city"
          type="text"
          placeholder="By"
          aria-label="By"
        />
        <ArgonInput
          v-model="manualCompany.phone"
          type="tel"
          placeholder="Telefon"
          aria-label="Telefon"
        />
        <ArgonInput
          v-model="manualCompany.email"
          type="email"
          placeholder="Email"
          aria-label="Email"
        />
        <ArgonInput
          v-model="manualCompany.vat"
          type="text"
          placeholder="CVR-nummer"
          aria-label="CVR-nummer"
        />
        <ArgonButton
          type="submit"
          fullWidth
          color="dark"
          variant="gradient"
          class="my-4 mb-2"
          :disabled="isLoading"
        >
          Tilføj manuelt
        </ArgonButton>
      </form>

      <div v-if="isLoading" class="loading-overlay">
        <div class="loading-spinner"></div>
      </div>

      <div
        v-if="message"
        :class="[
          'mt-4 text-center',
          message.type === 'error' ? 'text-danger' : 'text-success',
        ]"
      >
        {{ message.text }}
      </div>
    </div>
  </div>
</template>

<style scoped>
.company-add-modal {
  color: #ffffff;
  background-color: rgb(38, 39, 68);
  border-radius: 8px;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  position: relative;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-title {
  font-size: 1.25rem;
  font-weight: bold;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  color: #ffffff;
}

.modal-body {
  max-height: 70vh;
  overflow-y: auto;
}

.text-sm {
  font-size: 0.875rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-center {
  text-align: center;
}

.text-secondary {
  color: #6c757d;
}

.bg-white {
  background-color: #ffffff;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.text-danger {
  color: #dc3545;
}

.text-success {
  color: #28a745;
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;
}

.loading-spinner {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
