import { createRouter, createWebHistory } from "vue-router";
import { auth, db } from "@/firebase";
import { onAuthStateChanged } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";

// Import all view components
import Dashboard from "../views/Dashboard.vue";
import Tables from "../views/Tables.vue";
import Billing from "../views/Billing.vue";
import VirtualReality from "../views/VirtualReality.vue";
import RTL from "../views/Rtl.vue";
import Profile from "../views/Profile.vue";
import Signup from "../views/Signup.vue";
import Signin from "../views/Signin.vue";
import AdminSettings from "../views/adminSetting.vue";
import Calendar from "../views/Calendar.vue";
import PickDesigns from "../views/pickDesigns.vue";
import PreviewPost from "@/views/previewPost.vue"; // Adjust this path if necessary

const routes = [
  {
    path: "/",
    name: "/",
    redirect: "/signin",
  },
  {
    path: "/dashboard-default",
    name: "Dashboard",
    component: Dashboard,
    meta: { requiresAuth: true },
  },
  {
    path: "/tables",
    name: "Tables",
    component: Tables,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/billing",
    name: "Billing",
    component: Billing,
    meta: { requiresAuth: true },
  },
  {
    path: "/virtual-reality",
    name: "Virtual Reality",
    component: VirtualReality,
    meta: { requiresAuth: true },
  },
  {
    path: "/rtl-page",
    name: "RTL",
    component: RTL,
    meta: { requiresAuth: true },
  },
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    meta: { requiresAuth: true },
  },
  {
    path: "/signin",
    name: "Signin",
    component: Signin,
  },
  {
    path: "/signup",
    name: "Signup",
    component: Signup,
  },
  {
    path: "/admin/settings",
    name: "AdminSettings",
    component: AdminSettings,
    meta: { requiresAuth: true, requiresAdmin: true },
  },
  {
    path: "/calendar",
    name: "Calendar",
    component: Calendar,
    meta: { requiresAuth: true },
  },
  {
    path: "/pickdesigns",
    name: "PickDesigns",
    component: PickDesigns,
    meta: { requiresAuth: true },
  },
  {
    path: "/preview-post",
    name: "PreviewPost",
    component: PreviewPost,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  linkActiveClass: "active",
});

router.beforeEach(async (to, from, next) => {
  console.log("Navigating to:", to.path);

  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  const requiresAdmin = to.matched.some((record) => record.meta.requiresAdmin);

  let currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    console.log("Authentication required, checking auth state...");
    try {
      currentUser = await new Promise((resolve, reject) => {
        const unsubscribe = onAuthStateChanged(
          auth,
          (user) => {
            unsubscribe();
            resolve(user);
          },
          reject,
        );
      });
    } catch (error) {
      console.error("Error checking auth state:", error);
    }
  }

  if (requiresAuth && !currentUser) {
    console.log("No authenticated user, redirecting to signin");
    next("/signin");
  } else if (requiresAuth && currentUser) {
    console.log("User is authenticated, checking for admin rights if needed");
    const userDoc = await getDoc(doc(db, "users", currentUser.uid));
    const userData = userDoc.exists() ? userDoc.data() : null;

    if (userData && userData.email === "toft@fliva.com") {
      if (to.path !== "/pickdesigns") {
        console.log("Redirecting toft@fliva.com to pickdesigns");
        next("/pickdesigns");
      } else {
        next();
      }
    } else if (requiresAdmin) {
      const isAdmin = userData && userData.role === "admin";
      if (!isAdmin) {
        console.log("User is not an admin, redirecting to profile");
        next("/profile");
      } else {
        console.log("Admin access granted");
        next();
      }
    } else {
      if (to.path === "/calendar") {
        console.log("Accessing calendar page");
        // You can add additional checks here if needed for calendar access
      }
      next();
    }
  } else {
    console.log("No authentication required for this route");
    next();
  }
});

export default router;
