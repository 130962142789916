<script setup>
import { ref, computed, onMounted, onUnmounted } from "vue";
import { collection, onSnapshot, query } from "firebase/firestore"; // Removed unused imports
import { db } from "@/firebase";
import CompanyAddComponent from "./CompanyAddComponent.vue";

const showAddCompanyModal = ref(false);
const users = ref([]);
const searchQuery = ref(""); // Ref for søgefelt

const openAddCompanyModal = () => {
  showAddCompanyModal.value = true;
};

const closeAddCompanyModal = () => {
  showAddCompanyModal.value = false;
};

let unsubscribe;

onMounted(() => {
  const q = query(collection(db, "users"));
  unsubscribe = onSnapshot(q, (querySnapshot) => {
    const usersArray = [];
    querySnapshot.forEach((doc) => {
      usersArray.push({ id: doc.id, ...doc.data() });
    });
    users.value = usersArray;
  });
});

onUnmounted(() => {
  if (unsubscribe) unsubscribe();
});

// Computed property to filter users based on searchQuery
const filteredUsers = computed(() => {
  return users.value.filter(
    (user) =>
      user.name.toLowerCase().includes(searchQuery.value.toLowerCase()) ||
      user.email.toLowerCase().includes(searchQuery.value.toLowerCase()),
  );
});
</script>

<template>
  <div class="card">
    <div
      class="card-header pb-0 d-flex justify-content-between align-items-center"
    >
      <h6>Authors table</h6>
      <button @click="openAddCompanyModal" class="btn btn-primary btn-sm">
        Tilføj Virksomhed
      </button>
    </div>

    <!-- Søgebjælke -->
    <div class="search-bar px-3 py-2">
      <input
        type="text"
        v-model="searchQuery"
        placeholder="Søg efter en bruger..."
        class="form-control search-input"
      />
    </div>

    <div class="card-body px-0 pt-0 pb-2">
      <div class="table-responsive p-0">
        <table class="table align-items-center mb-0">
          <thead>
            <tr>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Author
              </th>
              <th
                class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7 ps-2"
              >
                Funktion
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Status
              </th>
              <th
                class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
              >
                Ansat
              </th>
              <th class="text-secondary opacity-7"></th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in filteredUsers" :key="user.id">
              <td>
                <div class="d-flex px-2 py-1">
                  <div class="d-flex flex-column justify-content-center">
                    <h6 class="mb-0 text-sm">{{ user.name }}</h6>
                    <p class="text-xs text-secondary mb-0">{{ user.email }}</p>
                  </div>
                </div>
              </td>
              <td>
                <p class="text-xs font-weight-bold mb-0">{{ user.role }}</p>
              </td>
              <td class="align-middle text-center text-sm">
                <span
                  :class="[
                    'badge badge-sm',
                    user.isOnline ? 'bg-gradient-success' : 'bg-gradient-alert',
                  ]"
                >
                  {{ user.isOnline ? "Online" : "Offline" }}
                </span>
              </td>
              <td class="align-middle text-center">
                <span class="text-secondary text-xs font-weight-bold">{{
                  user.id
                }}</span>
              </td>
              <td class="align-middle">
                <a
                  href="javascript:;"
                  class="text-secondary font-weight-bold text-xs"
                  data-toggle="tooltip"
                  data-original-title="Edit user"
                >
                  Edit
                </a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <!-- Modal -->
    <div v-if="showAddCompanyModal" class="modal-overlay">
      <div class="modal-container">
        <CompanyAddComponent @close="closeAddCompanyModal" />
      </div>
    </div>
  </div>
</template>

<style scoped>
.card-header {
  padding: 1.5rem 1.5rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid #e9ecef;
}

.btn-primary {
  color: #fff;
  background-color: #5e72e4;
  border-color: #5e72e4;
}

.btn-sm {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.2rem;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-container {
  background-color: #fff;
  border-radius: 8px;
  max-width: 500px;
  width: 100%;
  max-height: 90vh;
  overflow-y: auto;
}

.table {
  width: 100%;
  margin-bottom: 1rem;
  color: #525f7f;
  background-color: transparent;
}

.table th,
.table td {
  padding: 1rem;
  vertical-align: top;
  border-top: 1px solid #e9ecef;
}

.avatar-sm {
  width: 36px;
  height: 36px;
  font-size: 0.875rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}

.bg-gradient-success {
  background: linear-gradient(87deg, #2dce89 0, #2dcecc 100%) !important;
}

.bg-gradient-secondary {
  background: linear-gradient(87deg, #f7fafc 0, #f7f8fc 100%) !important;
}

.search-bar {
  display: flex;
  justify-content: flex-start; /* Venstrejusterer søgebjælken */
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  max-width: 400px; /* Sætter en maksimal bredde på søgebjælken */
  padding: 0.5rem;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
}
</style>
