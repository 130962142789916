<script setup>
import { ref, onBeforeUnmount, onBeforeMount } from "vue";
import { useStore } from "vuex";
import {
  signInWithEmailAndPassword,
  setPersistence,
  browserLocalPersistence,
  browserSessionPersistence,
} from "firebase/auth";
import { doc, getDoc, updateDoc } from "firebase/firestore";
import { auth, db } from "@/firebase";
import { useRouter } from "vue-router";

// Argon components
import ArgonInput from "@/components/ArgonInput.vue";
import ArgonSwitch from "@/components/ArgonSwitch.vue";
import ArgonButton from "@/components/ArgonButton.vue";

const email = ref("");
const password = ref("");
const rememberMe = ref(false); // Added ref for Remember Me
const errorMessage = ref("");
const router = useRouter();
const store = useStore();

const signIn = async () => {
  try {
    const persistenceType = rememberMe.value
      ? browserLocalPersistence
      : browserSessionPersistence;

    await setPersistence(auth, persistenceType);

    const userCredential = await signInWithEmailAndPassword(
      auth,
      email.value,
      password.value,
    );
    const user = userCredential.user;
    console.log("User signed in:", user);

    const userDocRef = doc(db, "users", user.uid);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      const userData = userDoc.data();
      await updateDoc(userDocRef, { isOnline: true });
      store.commit("setUser", { ...userData, id: user.uid, isOnline: true });

      if (userData.email === "toft@fliva.com") {
        router.push("/pickdesigns");
      } else if (userData.role === "admin") {
        router.push("/tables");
      } else {
        router.push("/profile");
      }
    } else {
      console.error("User document not found");
      errorMessage.value = "User data not found. Please contact support.";
    }
  } catch (error) {
    errorMessage.value = error.message;
  }
};

// Manage body classes and store state
const body = document.getElementsByTagName("body")[0];

onBeforeMount(() => {
  store.commit("setHideConfigButton", true);
  store.commit("setShowSidenav", false);
  store.commit("setShowFooter", false);
  body.classList.remove("bg-gray-100");
  body.classList.add("bg-black");
});

onBeforeUnmount(() => {
  store.commit("setHideConfigButton", false);
  store.commit("setShowSidenav", true);
  store.commit("setShowFooter", true);
  body.classList.remove("bg-black");
  body.classList.add("bg-gray-100");
});
</script>
<template>
  <div class="container top-0 position-sticky z-index-sticky">
    <div class="row">
      <div class="col-12">
        <navbar
          isBlur="blur border-radius-lg my-3 py-2 start-0 end-0 mx-4 shadow"
          v-bind:darkMode="true"
          isBtn="bg-gradient-success"
        />
      </div>
    </div>
  </div>
  <main class="mt-0 main-content">
    <section>
      <div class="page-header min-vh-100">
        <div class="container">
          <div class="row justify-content-center">
            <div class="col-xl-4 col-lg-5 col-md-7 d-flex flex-column mx-lg-0">
              <div class="card card-plain bg-black text-white">
                <div class="pb-0 card-header text-start">
                  <h4 class="font-weight-bolder text-pink-300">Sign In</h4>
                  <p class="mb-0 text-black-400">
                    Enter your email and password to sign in
                  </p>
                </div>
                <div class="card-body">
                  <form @submit.prevent="signIn" role="form">
                    <div class="mb-3">
                      <argon-input
                        id="email"
                        v-model="email"
                        type="email"
                        placeholder="Email"
                        name="email"
                        size="lg"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <div class="mb-3">
                      <argon-input
                        id="password"
                        v-model="password"
                        type="password"
                        placeholder="Password"
                        name="password"
                        size="lg"
                        class="text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
                      />
                    </div>
                    <argon-switch
                      id="rememberMe"
                      v-model="rememberMe"
                      name="remember-me"
                      class="text-white"
                    >
                      Remember me
                    </argon-switch>

                    <div v-if="errorMessage" class="text-red-500 text-sm">
                      {{ errorMessage }}
                    </div>

                    <div class="text-center">
                      <argon-button
                        type="submit"
                        class="mt-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 rounded-full hover:opacity-90 focus:ring-pink-500"
                        variant="gradient"
                        color="success"
                        fullWidth
                        size="lg"
                      >
                        Sign in
                      </argon-button>
                    </div>
                  </form>
                </div>
                <div class="px-1 pt-0 text-center card-footer px-lg-2">
                  <p class="mx-auto mb-4 text-sm text-gray-400">
                    Don't have an account?
                    <a
                      href="javascript:;"
                      class="text-pink-300 text-gradient font-weight-bold"
                      >Sign up</a
                    >
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
