<script setup>
import { ref, onMounted } from "vue";
import { doc, getDoc } from "firebase/firestore"; // Firestore-funktioner
import { auth, db } from "@/firebase"; // Importer din Firebase-opsætning
import setNavPills from "@/assets/js/nav-pills.js";

const activeTab = ref("App");
const isAdmin = ref(false); // Holder styr på, om brugeren er admin

onMounted(async () => {
  setNavPills();

  // Hent den aktuelle bruger fra Firebase Authentication
  const user = auth.currentUser;
  if (user) {
    const userId = user.uid;

    // Hent brugerdata fra Firestore
    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (userDoc.exists()) {
      const userData = userDoc.data();

      // Kontrollér om brugeren er admin
      if (userData.role === "admin") {
        isAdmin.value = true;
      }
    } else {
      console.error("Ingen brugerdata fundet i Firestore.");
    }
  } else {
    console.error("Ingen bruger er logget ind.");
  }
});

const setActiveTab = (tabName) => {
  activeTab.value = tabName;
};
</script>

<template>
  <div class="nav-wrapper position-relative end-0">
    <ul class="p-1 bg-transparent nav nav-pills nav-fill" role="tablist">
      <!-- App og Messages vises kun for almindelige brugere -->
      <template v-if="!isAdmin">
        <!-- App -->
        <li class="nav-item">
          <a
            class="px-0 py-1 mb-0 nav-link"
            :class="{ active: activeTab === 'App' }"
            @click="setActiveTab('App')"
            href="javascript:;"
            role="tab"
          >
            <svg
              class="text-dark"
              width="16px"
              height="16px"
              viewBox="0 0 42 42"
              xmlns="http://www.w3.org/2000/svg"
            >
              <!-- SVG paths -->
              <g fill="none" fill-rule="evenodd">
                <g fill="#FFFFFF" fill-rule="nonzero">
                  <path
                    d="M22.7597136,19.3090182 L38.8987031,11.2395234 C39.3926816,10.9925342 39.592906,10.3918611 39.3459167,9.89788265 C39.249157,9.70436312 39.0922432,9.5474453 38.8987261,9.45068056 L20.2741875,0.1378125 L19.0995,0.1378125 L3.1011696,8.13815822 C2.60720568,8.38517662 2.40701679,8.98586148 2.6540352,9.4798254 L21.8652864,19.3090182 C22.1468139,19.4497819 22.4781861,19.4497819 22.7597136,19.3090182 Z"
                  />
                  <path
                    d="M23.625,22.429159 L23.625,39.8805372 C23.625,40.4328219 24.0727153,40.8805372 24.625,40.8805372 L25.0722402,40.7749511 L41.2741875,32.673375 L42,31.5 L42,14.241659 L40.5527864,13.3472318 L24.1777864,21.5347318 C23.8390024,21.7041238 23.625,22.0503869 23.625,22.429159 Z"
                    opacity="0.7"
                  />
                  <path
                    d="M20.4472136,21.5347318 L1.4472136,12.0347318 C0.953235098,11.7877425 0.352562058,11.9879669 0.105572809,12.4819454 L0,12.929159 L19.5528096,40.7750766 C20.6474623,40.8218132 20.8944388,40.3278283 C20.963859,40.1889789 21,40.0358742 21,39.8806379 L21,22.429159 Z"
                    opacity="0.7"
                  />
                </g>
              </g>
            </svg>
            <span class="ms-1">App</span>
          </a>
        </li>

        <!-- Messages -->
        <li class="nav-item">
          <a
            class="px-0 py-1 mb-0 nav-link"
            :class="{ active: activeTab === 'Messages' }"
            @click="setActiveTab('Messages')"
            href="javascript:;"
            role="tab"
          >
            <svg
              class="text-dark"
              width="16px"
              height="16px"
              viewBox="0 0 40 44"
              xmlns="http://www.w3.org/2000/svg"
            >
              <!-- SVG paths -->
              <g fill="none" fill-rule="evenodd">
                <g fill="#FFFFFF" fill-rule="nonzero">
                  <path
                    d="M40,40 L36.3636364,40 L36.3636364,3.63636364 L5.45454545,3.63636364 L38.1818182,0 C39.1854545,0 40,0.814545455 40,1.81818182 L40,40 Z"
                    opacity="0.603585379"
                  />
                  <path
                    d="M30.9090909,7.27272727 L1.81818182,7.27272727 C0.814545455,7.27272727 0,8.08727273 0,9.09090909 L0,41.8181818 C0,42.8218182 0.814545455,43.6363636 1.81818182,43.6363636 L30.9090909,43.6363636 C31.9127273,43.6363636 32.7272727,42.8218182 32.7272727,41.8181818 L32.7272727,9.09090909 C32.7272727,8.08727273 31.9127273,7.27272727 30.9090909,7.27272727 Z"
                  />
                </g>
              </g>
            </svg>
            <span class="ms-1">Messages</span>
          </a>
        </li>
      </template>

      <!-- Admin-only tabs (vises kun for admins) -->
      <template v-if="isAdmin">
        <!-- Settings -->
        <li class="nav-item">
          <a
            class="px-0 py-1 mb-0 nav-link"
            :class="{ active: activeTab === 'Settings' }"
            @click="setActiveTab('Settings')"
            href="javascript:;"
            role="tab"
          >
            <svg
              class="text-dark"
              width="16px"
              height="16px"
              viewBox="0 0 40 40"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <g fill="#FFFFFF" fill-rule="nonzero">
                  <polygon
                    opacity="0.596981957"
                    points="18.0883333 15.7316667 11.1783333 8.82166667 6.66666667 0 0 6.66666667 6.66666667 13.3333333 15.315 17.6716667"
                  />
                  <path
                    d="M33.785,11.285 L28.715,6.215 L34.0616667,0.868333333 C32.82,0.315 31.4483333,0 30,0 C24.4766667,0 20,4.47666667 20,10 C20,10.99 20.1483333,11.9433333 20.4166667,12.8466667 L2.435,27.3966667 C0.95,28.7083333 0.0633333333,30.595 0.00333333333,32.5733333 C-0.0583333333,34.5533333 0.71,36.4916667 2.11,37.89 C3.47,39.2516667 5.27833333,40 7.20166667,40 C9.26666667,40 11.23666667,39.1133333 12.60333333,37.565 L27.1533333,19.5833333 C28.0566667,19.8516667 29.01,20 30,20 C35.5233333,20 40,15.5233333 40,10 C40,8.55166667 39.685,7.18 39.1316667,5.93666667 L33.785,11.285 Z"
                  />
                </g>
              </g>
            </svg>
            <span class="ms-1">Settings</span>
          </a>
        </li>

        <!-- Menu -->
        <li class="nav-item">
          <a
            class="px-0 py-1 mb-0 nav-link"
            :class="{ active: activeTab === 'Menu' }"
            @click="setActiveTab('Menu')"
            href="javascript:;"
            role="tab"
          >
            <svg
              class="text-dark"
              width="16px"
              height="16px"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g fill="none" fill-rule="evenodd">
                <g fill="#FFFFFF" fill-rule="nonzero">
                  <path d="M4 6H20V8H4V6ZM4 11H20V13H4V11ZM4 16H20V18H4V16Z" />
                </g>
              </g>
            </svg>
            <span class="ms-1">Menu</span>
          </a>
        </li>
      </template>
    </ul>
  </div>
</template>
