<template>
  <main class="main-content">
    <div class="page-background">
      <div class="card-wrapper">
        <div class="card">
          <div
            class="card-header pb-0 d-flex justify-content-between align-items-center"
          >
            <h6>Søg efter designs via UUID eller filtrer</h6>
          </div>

          <!-- UUID søgefelt -->
          <div class="search-bar px-3 py-2">
            <input
              type="text"
              v-model="searchUuid"
              placeholder="Søg efter UUID..."
              class="form-control search-input"
              @keyup.enter="searchData"
            />
            <argon-button
              @click="searchData"
              variant="gradient"
              color="secondary"
              size="sm"
              class="ml-2"
            >
              Søg
            </argon-button>
          </div>

          <!-- Udvidet søgning knap -->
          <div class="filter-bar px-3 py-2">
            <argon-button
              type="submit"
              class="bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 rounded-full hover:opacity-90 focus:ring-pink-500"
              variant="gradient"
              color="success"
              size="lg"
              @click="toggleAdvancedSearch"
            >
              Udvidet søgning
            </argon-button>
          </div>

          <!-- Filtrering og sortering (vises kun, når udvidet søgning er aktiv) -->
          <div v-if="advancedSearch" class="filter-bar px-3 py-2">
            <label for="selectKaede">Vælg kæde:</label>
            <select
              v-model="selectedKaede"
              id="selectKaede"
              class="form-control"
            >
              <option value="">Vælg en kæde</option>
              <option
                v-for="kaede in availableKaeder"
                :key="kaede"
                :value="kaede"
              >
                {{ kaede }}
              </option>
            </select>

            <label for="selectGroup" class="ml-3">Vælg design gruppe:</label>
            <select
              v-model="selectedGroup"
              id="selectGroup"
              class="form-control ml-2"
            >
              <option value="">Vælg en gruppe</option>
              <option
                v-for="group in availableGroups"
                :key="group"
                :value="group"
              >
                {{ group }}
              </option>
            </select>

            <!-- Sorteringsvalg (flyttet op før knappen) -->
            <label for="sortOrder" class="mt-3">Sorter designs efter:</label>
            <select v-model="sortOrder" id="sortOrder" class="form-control">
              <option value="nyeste">Nyeste først</option>
              <option value="ældste">Ældste først</option>
            </select>

            <!-- Flyttet knappen nedenunder sorteringsvalget -->
            <argon-button
              @click="filterResults"
              variant="gradient"
              color="secondary"
              size="sm"
              class="mt-3"
            >
              Filtrer designs
            </argon-button>
          </div>

          <!-- Designs Table (vises efter filtrering eller søgning) -->
          <div
            v-if="searched && filteredResults && filteredResults.length > 0"
            class="card-body px-0 pt-0 pb-2"
          >
            <div class="table-responsive table-scrollable p-0">
              <table class="table align-items-center mb-0">
                <thead>
                  <tr>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Thumbnail
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      UUID
                    </th>
                    <th
                      class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      ID
                    </th>
                    <th
                      class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7"
                    >
                      Handling
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="result in filteredResults" :key="result.uuid">
                    <td>
                      <div class="d-flex px-2 py-1">
                        <img
                          :src="result.file"
                          alt="Thumbnail"
                          class="thumbnail-img"
                        />
                      </div>
                    </td>
                    <td>
                      <div class="d-flex px-2 py-1">
                        <div class="d-flex flex-column justify-content-center">
                          <h6 class="mb-0 text-sm">{{ result.uuid }}</h6>
                        </div>
                      </div>
                    </td>
                    <td class="align-middle text-center">
                      <span class="text-secondary text-xs font-weight-bold">{{
                        result.id
                      }}</span>
                    </td>
                    <td class="align-middle text-center">
                      <argon-button
                        @click="deleteDesign(result.id)"
                        variant="gradient"
                        color="danger"
                        size="sm"
                      >
                        Slet
                      </argon-button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

          <p
            v-else-if="searched && filteredResults.length === 0"
            class="text-center mt-4"
          >
            Ingen resultater fundet
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, watch } from "vue";
import axios from "axios";

// Import ArgonButton komponenten
import ArgonButton from "@/components/ArgonButton.vue";

// Initialisering af reaktive data
const searchUuid = ref(""); // Søgning via UUID
const searchResults = ref([]); // Alle hentede designs
const filteredResults = ref([]); // Filtrerede resultater
const availableKaeder = ref([]); // Muligheder for "kæde" i filtrering
const availableGroups = ref([]); // Muligheder for "design gruppe" i filtrering
const selectedKaede = ref(""); // Valgt kæde
const selectedGroup = ref(""); // Valgt design gruppe
const sortOrder = ref("nyeste"); // Sortering ("nyeste" eller "ældste")
const advancedSearch = ref(false); // Udvidet søgning slået til/fra
const searched = ref(false); // Flag til at indikere om der er søgt/filtreret

// Funktion til at hente alle designs én gang og sortere filtre alfabetisk
async function fetchAllDesigns() {
  const url = "https://cloudrun-nvxa75jwha-ey.a.run.app/getDesignTxt";
  try {
    const response = await axios.post(url);
    if (response.status === 200 && response.data && !response.data.error) {
      searchResults.value = response.data.data.flatMap((group) =>
        group.data.map((item) => ({
          ...item,
          group: group.postmastertxtGroup,
        })),
      );

      // Sortér kaeder og grupper alfabetisk til filtrering
      availableKaeder.value = [
        ...new Set(searchResults.value.map((item) => item.kaede)),
      ].sort();
      availableGroups.value = [
        ...new Set(searchResults.value.map((item) => item.group)),
      ].sort();
    } else {
      console.error("Unexpected data structure in response");
    }
  } catch (error) {
    console.error("Error fetching data:", error);
    searchResults.value = []; // Sørg for at der ikke er nogen null værdier
  }
}

// Funktion til at søge efter designs baseret på UUID
async function searchData() {
  searched.value = true;
  filteredResults.value = searchResults.value.filter((item) =>
    item.uuid.includes(searchUuid.value),
  );
  sortResults(); // Sortér resultaterne efter den valgte sorteringsrækkefølge
}

// Funktion til at filtrere resultater baseret på valgte filtre
function filterResults() {
  searched.value = true;
  filteredResults.value = searchResults.value.filter((item) => {
    const kaedeMatch = selectedKaede.value
      ? item.kaede === selectedKaede.value
      : true;
    const groupMatch = selectedGroup.value
      ? item.group === selectedGroup.value
      : true;
    return kaedeMatch && groupMatch;
  });
  sortResults(); // Sortér resultaterne
}

// Funktion til at sortere resultater baseret på den valgte sorteringsrækkefølge
function sortResults() {
  if (sortOrder.value === "nyeste") {
    filteredResults.value.sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt),
    );
  } else {
    filteredResults.value.sort(
      (a, b) => new Date(a.createdAt) - new Date(b.createdAt),
    );
  }
}

// Funktion til at slette individuelle designs
async function deleteDesign(id) {
  try {
    const response = await axios.post(
      "https://hook.eu1.make.com/hrtnxt5nxlpr220uq3evhxows1ixjpqp",
      { id },
    );

    if (response.status === 200) {
      // Fjerner det slettede element fra tabellen
      filteredResults.value = filteredResults.value.filter(
        (item) => item.id !== id,
      );
    } else {
      console.error("Error while deleting the design");
    }
  } catch (error) {
    console.error("Error while sending delete request:", error);
  }
}

// Skift mellem at vise udvidet søgning eller ej
function toggleAdvancedSearch() {
  advancedSearch.value = !advancedSearch.value;
}

// Overvåg ændringer i sorteringsrækkefølgen og opdater resultaterne
watch(sortOrder, () => {
  if (filteredResults.value.length > 0) {
    sortResults();
  }
});

// Hent alle designs ved komponentens mount
fetchAllDesigns();
</script>

<style scoped>
html,
body {
  height: 100%;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.main-content {
  height: 100vh;
  max-height: 100vh;
  overflow: hidden;
  position: relative;
}

.page-background {
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 50px; /* Juster padding for korrekt afstand */
  box-sizing: border-box;
}

.card-wrapper {
  position: relative;
  z-index: 2;
  width: 70%;
  margin: 0 auto; /* Ensure no unnecessary margins */
  padding-top: 50px; /* Adjust padding to move content */
}

.search-bar {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 1rem;
}

.search-input {
  width: 100%;
  max-width: 300px;
  padding: 0.5rem;
  border: 1px solid #e9ecef;
  border-radius: 0.25rem;
  height: calc(1.5em + 0.75rem + 2px); /* Standard height adjustment */
}

.thumbnail-img {
  width: 250px;
  height: 250px;
  object-fit: cover;
  border-radius: 4px;
}

.table-scrollable {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}

.card-body {
  padding: 1rem;
  overflow: hidden;
}
</style>
