import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";
import router from "./router";
import "./assets/css/nucleo-icons.css";
import "./assets/css/nucleo-svg.css";
import ArgonDashboard from "./argon-dashboard";
import onlineStatus from "./plugins/onlineStatus";

const app = createApp(App);
app.use(store);
app.use(router);
app.use(ArgonDashboard);
app.use(onlineStatus);
app.mount("#app");
