<script setup>
import { ref, onMounted, onBeforeUnmount } from "vue";
import { Calendar } from "@fullcalendar/core";
import dayGridPlugin from "@fullcalendar/daygrid";

const calendarEl = ref(null);
let calendar;

const props = defineProps({
  id: {
    type: String,
    default: "widget-calendar",
  },
  title: {
    type: String,
    default: "Calendar",
  },
  day: {
    type: String,
    default: "",
  },
  year: {
    type: String,
    default: "",
  },
  initialView: {
    type: String,
    default: "dayGridMonth",
  },
  initialDate: {
    type: String,
    default: "2023-09-11",
  },
  events: {
    type: Array,
    default: () => [
      {
        title: "Meeting",
        start: "2023-09-12",
        end: "2023-09-12",
        className: "bg-gradient-success",
      },
      // Add more events as needed
    ],
  },
  selectable: {
    type: Boolean,
    default: true,
  },
  editable: {
    type: Boolean,
    default: true,
  },
});

onMounted(() => {
  console.log("Calendar component mounted");
  if (calendarEl.value) {
    calendar = new Calendar(calendarEl.value, {
      plugins: [dayGridPlugin],
      initialView: props.initialView,
      selectable: props.selectable,
      editable: props.editable,
      events: props.events,
      initialDate: props.initialDate,
      headerToolbar: {
        start: "title",
        center: "",
        end: "today prev,next",
      },
    });

    calendar.render();
  } else {
    console.error("Calendar element not found");
  }
});

onBeforeUnmount(() => {
  if (calendar) {
    calendar.destroy();
  }
});
</script>

<template>
  <div class="calendar-container">
    <h1>{{ title }}</h1>
    <div class="card widget-calendar">
      <div class="p-3 pb-0 card-header">
        <h6 class="mb-0">{{ props.title }}</h6>
        <div class="d-flex">
          <div class="mb-0 text-sm p font-weight-bold widget-calendar-day">
            {{ props.day }}
          </div>
          <div class="mb-1 text-sm p font-weight-bold widget-calendar-year">
            {{ props.year }}
          </div>
        </div>
      </div>
      <div class="p-3 card-body">
        <div
          ref="calendarEl"
          :id="props.id"
          data-toggle="widget-calendar"
        ></div>
      </div>
    </div>
  </div>
</template>

<style scoped>
.calendar-container {
  width: 100%;
  max-width: 800px;
  margin: 0 auto;
}

.widget-calendar {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}
</style>
