// src/firebase.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAx_5ZnR__8sizS5_1k3uP-gilviDiGO6Q",
  authDomain: "toft-d4f39.firebaseapp.com",
  projectId: "toft-d4f39",
  storageBucket: "toft-d4f39.appspot.com",
  messagingSenderId: "277892110082",
  appId: "1:277892110082:web:4d4578f88e521c2fb3141b",
  measurementId: "G-L976F1ZSTY",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { auth, db };
