<script setup>
import { ref, onMounted } from "vue";
import { auth, db } from "@/firebase";
import { doc, getDoc } from "firebase/firestore";
import { useRouter } from "vue-router";

const isAdmin = ref(false);
const router = useRouter();

onMounted(async () => {
  try {
    const user = auth.currentUser;
    if (user) {
      console.log("User found:", user.uid);
      const userDocRef = doc(db, "users", user.uid);
      const userDoc = await getDoc(userDocRef);

      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("User data from Firestore:", userData);

        if (userData.role !== "admin") {
          console.log("User is not admin, redirecting to /home");
          router.push("/home"); // Omdiriger, hvis brugeren ikke er admin
        } else {
          isAdmin.value = true;
          console.log("User is admin, access granted");
        }
      } else {
        console.error("No user document found in Firestore");
      }
    } else {
      console.error("No user is logged in");
      router.push("/signin");
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
});
</script>

<template>
  <div v-if="isAdmin">
    <h1>Admin API Settings</h1>
    <form>
      <!-- API settings form her -->
      <div>
        <label>Facebook token</label>
        <input type="text" placeholder="Facebook token" />
      </div>
      <div>
        <label>T2 - apikey</label>
        <input type="text" placeholder="T2 - apikey" />
      </div>
      <div>
        <label>T2 - api secret</label>
        <input type="text" placeholder="T2 - api secret" />
      </div>
      <button type="submit">Gem indstillinger</button>
    </form>
  </div>
  <div v-else>
    <p>Du har ikke adgang til denne side.</p>
  </div>
</template>
