<template>
  <main class="main-content">
    <div class="page-background">
      <!-- The card with a consistent style -->
      <div class="card bg-black text-white p-4">
        <div class="pb-0 card-header text-start">
          <h4 class="font-weight-bolder text-pink-300">Preview Creative</h4>
          <p class="mb-0 text-gray-400">
            Enter a Creative ID to fetch the preview
          </p>
        </div>
        <div class="card-body">
          <div class="mb-3">
            <input
              v-model="searchCreativeId"
              type="text"
              placeholder="Indtast Creative ID..."
              class="form-control text-white placeholder-gray-500 bg-black border-gray-600 focus:ring-pink-500"
            />
          </div>

          <div class="text-center">
            <button
              @click="fetchPreview"
              class="btn mt-4 bg-gradient-to-r from-purple-500 to-pink-500 text-white py-2 rounded-full hover:opacity-90 focus:ring-pink-500"
            >
              Hent Preview
            </button>
          </div>

          <!-- iPhone Mockup -->
          <div v-if="previewIframe" class="iphone-x mt-5">
            <iframe
              ref="creativeIframe"
              :srcdoc="previewIframe"
              class="iframe-preview"
              frameborder="0"
              scrolling="no"
            ></iframe>
          </div>

          <!-- Error message -->
          <p
            v-else-if="searched && !previewIframe"
            class="text-center mt-4 text-red-500"
          >
            Ingen creative fundet. Prøv et andet Creative ID.
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script setup>
import { ref, nextTick } from "vue";
import axios from "axios";

const searchCreativeId = ref(""); // User input
const previewIframe = ref(null); // Preview iframe HTML
const searched = ref(false); // Whether a search has been made

// Function to fetch the creative preview from Facebook API
async function fetchPreview() {
  if (!searchCreativeId.value) {
    alert("Please enter a Creative ID");
    return;
  }

  try {
    const response = await axios.get(
      `https://graph.facebook.com/v20.0/${searchCreativeId.value}/previews`,
      {
        params: {
          ad_format: "MOBILE_FEED_STANDARD",
          access_token:
            "EAAvJ5UcfHO0BAMufob4DZCtz7rG33RZBb98Ddiy1KMo0m5UPRmYsrJ6zr5XYid2mbY26OK7m9hcc9ngk2MaNckLN3jaOEAwnqIbhSv9HZCzopfrqm8TYQop93y4SEjZCPtLZCeg9W13NA83CRaQdBHCNAsDXBEbXnyiyyjtn7XAZDZD",
        },
      },
    );

    if (response.data && response.data.data && response.data.data[0].body) {
      let iframeHTML = response.data.data[0].body;

      iframeHTML = iframeHTML
        .replace(/width="\d+"/, 'width="375px"')
        .replace(/height="\d+"/, 'height="800px"')
        .replace(/scrolling="yes"/, 'scrolling="yes"');

      previewIframe.value = iframeHTML;
      await nextTick();
    } else {
      previewIframe.value = null;
    }
    searched.value = true;
  } catch (error) {
    console.error("Error fetching preview:", error);
    previewIframe.value = null;
    searched.value = true;
  }
}
</script>

<style scoped>
.main-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  padding: 20px;
  box-sizing: border-box;
}

.page-background {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.card-wrapper {
  width: 100%;
  max-width: 600px; /* Set a reasonable max-width for the card */
  padding: 20px;
}

.card {
  background-color: #111;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(255, 255, 255, 0.1);
  width: 30%;
  padding: 20px;
}

.text-pink-300 {
  color: #ec4899;
}

.text-gray-400 {
  color: rgba(156, 163, 175, 1);
}

.form-control {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border-radius: 8px;
  border: 1px solid #ced4da;
}

.bg-black {
  background-color: #111;
}

.btn {
  width: 100%;
  padding: 12px 30px;
  font-size: 18px;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.bg-gradient-to-r {
  background: linear-gradient(to right, #9f7aea, #ec4899);
}

.iphone-x {
  position: relative;
  margin: 40px auto;
  width: 375px;
  height: 812px;
  background-color: #ffffff;
  border-radius: 40px;
  box-shadow:
    0px 0px 0px 11px #1f1f1f,
    0px 0px 0px 13px #191919,
    0px 0px 0px 20px #111;
}

.iframe-preview {
  width: 100%;
  height: 100%;
  border: none;
  border-radius: 40px;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}
</style>
